"use client";

import { ShortcutValueU } from "enums/shortcut.enum";
import { createSafeContext } from "lib/createSafeContext";
import { ReactElement, ReactNode } from "react";
import { HotkeyCallback, HotkeysProvider } from "react-hotkeys-hook";

type RegistryItem = {
  callback: HotkeyCallback;
  icon: ReactElement;
};

const registry = new Map<ShortcutValueU, RegistryItem | null>();

const [ShortcutsRegistryProvider, useShortcutsRegistry] = createSafeContext<{
  registry: typeof registry;
  addToRegistry: (shortcut: ShortcutValueU, item: RegistryItem) => void;
  removeFromRegistry: (shortcut: ShortcutValueU) => void;
}>("");

export function ClientifiedHotkeysProvider({
  children,
}: { children: ReactNode }) {
  const addToRegistry = (shortcut: ShortcutValueU, item: RegistryItem) => {
    registry.set(shortcut, item);
  };

  const removeFromRegistry = (shortcut: ShortcutValueU) => {
    registry.delete(shortcut);
  };

  return (
    <ShortcutsRegistryProvider
      value={{ registry, addToRegistry, removeFromRegistry }}
    >
      <HotkeysProvider>{children}</HotkeysProvider>
    </ShortcutsRegistryProvider>
  );
}

export { useShortcutsRegistry };
