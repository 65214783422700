"use client";
import { createSafeContext } from "@open/hoose/utils/create-safe-context";
import { IS_SERVER } from "lib/env";
import _, { set as lodashSet } from "lodash";
import React, { useEffect, useState } from "react";

const [SafeAppConfigProvider, useAppConfig] = createSafeContext<{
  state: Record<string, any>;
  set: <T extends any = unknown>(path: string, value: T) => void;
  get: <T extends any = unknown>(path: string) => T;
}>("");

const KEY = "app-config";
const SYNC_TO_LOCAL_STORAGE = true;

function getInitialData(key: string) {
  if (IS_SERVER) return {};
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : {};
}

function AppConfigProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<Record<string, any>>({});

  useEffect(() => {
    if (IS_SERVER) return;
    let initial = getInitialData(KEY);
    if (initial) {
      setState(initial);
    }
  }, []);

  const set = React.useCallback((path: string, value: any) => {
    setState((prev) => {
      const newState = { ...prev };
      lodashSet(newState, path, value);
      if (SYNC_TO_LOCAL_STORAGE) {
        localStorage.setItem(KEY, JSON.stringify(newState));
      }
      return newState;
    });
  }, []);
  const get = React.useCallback((path: string) => _.get(state, path), [state]);
  return (
    <SafeAppConfigProvider value={{ get, set, state }}>
      {children}
    </SafeAppConfigProvider>
  );
}

enum AppConfigPathEnum {
  "animation.copilot-magic-orb" = "animation.copilot-magic-orb",
  "animation.copilot-magic-orb-pulse" = "animation.copilot-magic-orb-pulse",
  "appearance.color-mode" = "appearance.color-mode",
  "copilot.isOpen" = "copilot.isOpen",
  "copilot.isFullScreen" = "copilot.isFullScreen",
  "aside.open" = "aside.open",
  "advertising.opn_welcome_video" = "advertising.opn_welcome_video",
}

function useConfigState<T extends any = unknown>(
  path: AppConfigPathEnum,
  initial?: T,
): [T | undefined, (value: T) => void] {
  const { set, get } = useAppConfig();
  const value = get<T>(path) ?? initial;

  const setValue = (value: T) => set(path, value);
  return [value, setValue] as const;
}

export { useAppConfig, AppConfigProvider, useConfigState, AppConfigPathEnum };
