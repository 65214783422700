"use client";
import Script from "next/script";
import { useEffect } from "react";

declare global {
  interface Window {
    Featurebase: any;
  }
}
const FeedbackProvider = () => {
  useEffect(() => {
    const win = window;
    if (typeof win.Featurebase !== "function") {
      win.Featurebase = function () {
        win.Featurebase.q = win.Featurebase.q || [];
        win.Featurebase.q.push(arguments);
      };
    }
    win.Featurebase("initialize_feedback_widget", {
      organization: "open", // Replace this with your organization name, copy-paste the subdomain part from your Featurebase workspace url (e.g. https://*yourorg*.featurebase.app)
      theme: "light",
      locale: "en", // Change the language, view all available languages from https://help.featurebase.app/en/articles/8879098-using-featurebase-in-my-language
    });
  }, []);

  return (
    <>
      <Script
        src="https://do.featurebase.app/js/sdk.js"
        defer
        id="featurebase-sdk"
      />
    </>
  );
};

export default FeedbackProvider;
