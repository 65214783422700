export enum SubSection {
  INBOX = "Inbox",
  CHANNELS = "Channels",

  SEQUENCING = "Sequencing",
  EMAILS = "Emails",

  PHONE_AGENTS = "PhoneAgents",

  CHANNELS__SMS = "Channels:Sms",

  CONTACTS = "Contacts",

  REPORTS = "Reports",
  REPORTS__AI_INSIGHTS = "AiInsights",
  REPORTS__VOICE_OF_CUSTOMER = "VoiceOfCustomer",
  REPORTS__DATA_EXPORT = "DataExport",
  REPORTS__HANDOFFS = "Handoffs",

  SETTINGS__GENERAL = "General",
  SETTINGS__AI_PROFILE = "AiProfile",
  SETTINGS__AI_AUTOPILOT = "AiAutopilot",
  SETTINGS__AI_PERFORMANCE_CHECKS = "AiPerformanceChecks",
  SETTINGS__DOMAINS = "Domains",
  SETTINGS__INTEGRATIONS = "Integrations",
  SETTINGS__OFFICE_HOURS = "OfficeHours",
  SETTINGS__API_TOKENS = "ApiTokens",
  SETTINGS__ORG_TAGS = "OrgTags",
  SETTINGS__GROUPS = "Groups",
  SETTINGS__APPEARANCE = "Appearance",
  SETTINGS__TEAM_MEMBERS = "TeamMembers",
  SETTINGS__SLACK = "Slack",
  SETTINGS__WEBHOOKS = "Webhooks",
  SETTINGS__SECURITY = "Security",
  SETTINGS__HANDOFF = "Handoff",
  SETTINGS__TICKETING = "Ticketing",
  SETTINGS__KNOWLEDGEBASE = "Knowledgebase",
  SETTINGS__PHONE_NUMBERS = "PhoneNumbers",
  SETTINGS__AGENT_ASSIGNMENT = "AgentAssignment",
  AI__ACTIONS = "Actions",
  AI__KNOWLEDGE = "Knowledge",
  AI__CUSTOM_TRAINING = "CustomTraining",
  AI__PROFILE = "Profile",
  AI__AUTOPILOT = "Autopilot",

  EMAIL_TEMPLATES = "EmailTemplates",

  CHANNELS__WEB = "Web",
  CHANNELS__PHONE = "Phone",
  CHANNELS__EMAIL = "Email",
  CHANNELS__SLACK = "Slack",
  CHANNELS__WHATSAPP = "Whatsapp",
}
